<!--  -->
<template>
	<div class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    ">
		<Headers></Headers>
		<div id="content" class="site-content">
			<div id="primary" class="content-area">
				<main id="main" class="site-main" role="main">
					<div class="content_container directors">
						<!-- 头部banner图 -->
						<div class="section_banner_container" style="margin-bottom: 30px">
							<div class="section_banner">
								<div class="container">
									<div class="row">
										<div class="col-xs-12 col-sm-11">
											<div class="section_banner_content">
												<h1 class="section_banner_heading">{{ title }}</h1>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 董事会结构 -->
						<div class="container">
							<!-- <div class="left col-xs-12 col-sm-12">
                <p style="text-align: center; margin-bottom: 30px">
                  {{ tabletitle }}
                </p>
              </div> -->
							<div class="dirHeader">
								<div class="title">
									<strong>{{ tabletitle }}</strong>
								</div>
								<div class="span"></div>
							</div>
							<div class="right col-xs-12 col-sm-12">
								<el-table :data="tableList" style="width: 100%">
									<el-table-column prop="namevalue" label="名字" width="135" align="center">
									</el-table-column>
									<el-table-column prop="titlevalue" label="职位" width="180" align="center">
									</el-table-column>
									<el-table-column label="委员会" align="center">
										<el-table-column prop="managementvalue" label="经理" align="center">
										</el-table-column>
										<el-table-column prop="auditvalue" label="审计委员会" align="center">
										</el-table-column>
										<el-table-column prop="compensationvalue" label="薪酬委员会" align="center">
										</el-table-column>
										<el-table-column prop="nominationvalue" label="提名与公司治理委员会" align="center">
										</el-table-column>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
		<!-- #content -->
		<Footers></Footers>
	</div>
</template>

<script>
	import listData from './data.json'
	import Headers from "../../components/header/header.vue";
	import Footers from "../../components/footer/footer.vue";
	import {
		queryTable
	} from "../../util/api.js";
	export default {
		//import引入的组件需要注入到对象中才能使用
		components: {
			Headers,
			Footers
		},
		metaInfo() {
			return {
				title: this.title + "-盈喜集团股份有限公司|ATXG",
				meta: [{
						name: "keywords",
						content: "盈喜集团董事会结构，盈喜审计委员会，盈喜薪酬委员会，ATXG",
					},
					{
						name: "description",
						content: "盈喜集团建立了符合上市企业要求和标准的董事会结构，搭建了经理管理团队和审计委员会、薪酬委员会、提名与公司治理委员会。",
					},
				],
			};
		},
		data() {
			//这里存放数据
			return {
				// 页面名称
				title: "",
				// 表格名称
				tabletitle: "",
				tableList: [],
				// 表格数据
				tableData: [{
						name: "洪志达",
						title: "董事局主席&CEO",
						management: "●",
						audit: "",
						compensation: "",
						nomination: "",
					},
					{
						name: "洪志旺",
						title: "董事",
						management: "○",
						audit: "",
						compensation: "",
						nomination: "",
					},
					{
						name: "伍颂慈",
						title: "独立董事",
						management: "",
						audit: "●",
						compensation: "",
						nomination: "",
					},
					{
						name: "于佳鑫",
						title: "独立董事",
						management: "○",
						audit: "●",
						compensation: "○",
						nomination: "",
					},
					{
						name: "李伟林",
						title: "独立董事",
						management: "○",
						audit: "●",
						compensation: "○",
						nomination: "",
					},
				],
			};
		},
		//监听属性 类似于data概念
		computed: {},
		//监控data中的数据变化
		watch: {},
		//方法集合
		methods: {
			// 加载
			async init() {
				let that = this;
				// this.title = this.$route.query.tableTitle;
				this.title = "董事会结构";

				// this.tabletitle = this.$route.query.title;
				this.tabletitle = "董事会结构";

				// const { data: res } = await this.$http.get(queryTable, {
				//   // params: { pageCode: this.$route.query.id, pageSize: 10000 },

				//   params: { pageCode: "board", pageSize: 10000 },
				// });
				console.log('数组数据123',listData)
				let res = JSON.parse(JSON.stringify(listData))
				console.log(res);
				let arrList = [];
				res.result.records.forEach(function(item, index) {
					item.tableRowList.forEach(function(item2, index2) {
						arrList.push(JSON.parse(item2.rowValue));
					});
				})
				this.tableList = arrList
				console.log('数组数据', this.tableList);
			},
		},
		//生命周期 - 创建完成（可以访问当前this实例）
		created() {
			this.init();
		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {},
		beforeCreate() {}, //生命周期 - 创建之前
		beforeMount() {}, //生命周期 - 挂载之前
		beforeUpdate() {}, //生命周期 - 更新之前
		updated() {}, //生命周期 - 更新之后
		beforeDestroy() {}, //生命周期 - 销毁之前
		destroyed() {}, //生命周期 - 销毁完成
		activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
	};
</script>
<style lang='less' scoped>
	.directors {
		text-align: left;

		.section_banner {
			background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
		}

		.container {
			.dirHeader {
				// text-align: center;
				display: flex;
				flex-direction: column;
				// align-items: center;
				border-bottom: 1px solid #3082c0;
				position: relative;
				padding-bottom: 20px;
				margin-bottom: 20px;

				.title {
					font-family: Arial, Helvetica, sans-serif;
					font-size: 32px;
					color: #3082c0;
				}

				.span {
					z-index: 1;
					width: 199px;
					height: 10px;
					background-color: rgb(4, 107, 186);
					position: absolute;
					top: 50px;
				}
			}

			& /deep/ tr {
				color: #5a5a5a;
			}

			p {
				margin: 0;
			}

			.left {
				p {
					font-size: 48px;
				}
			}

			.right {
				p {
					font-size: 20px;
				}
			}
		}
	}
</style>